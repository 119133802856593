.App {
  text-align: center;
  padding: 5px 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.Header {
  line-height: 20px;
  background: #E6E6E6;
  border: 1px solid black;
  border-bottom: 0;
}

.Main {
  background: #887674;
  padding: 10px;
  height: 100%;
  border: 2px solid black;
}

.Center {
    height: 100%;
}

.Gallery {
  background-color: #C8B4B4;
  border: 1px #000;
  border: 2px solid black;
}

.ChoiceArea {

  min-height: 470px;
  min-width: 100%;

  background-color:#ddc;
  //background-size: 100%;
  border:solid 2em #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  //border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  box-shadow:0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
  box-sizing:border-box;
  display:inline-block;
  //margin:1vh 2vw;
  /*height:60vh;*/
  //height: 100%;
  padding:1em;
  position:relative;
  text-align:center;
  &::before {
   //border-radius:2px;
   bottom:-1em;
   box-shadow:0 2px 5px 0 rgba(0,0,0,0.25) inset;
   content:"";
   left:-1em;
   position:absolute;
   right:-1em;
   top:-1em;
  }
  //&::after {
  // border-radius:2px;
  // bottom:-2.5vmin;
  // box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
  // content:"";
  // left:-2.5vmin;
  // position:absolute;
  // right:-2.5vmin;
  // top:-2.5vmin;
  //}
}

.Heading {
  background: #FFF;
  width: 100%;
  line-height: 30px;
  margin-top: 10px;
  align-self: center;
}

.ImageWrapper {
  display: inline-table;
  position: relative;
  height: auto;
  float: left;
  margin: 10px;
}

.DeselectMark {
  width: 20px;
  height: 20px;
  background-color: #f44336;
  font-weight: bold;
  color: #fff;
  padding: 0;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  right: 4px;
  cursor: pointer;
  display: none;
}

.ImageComment {
  width: 100%;
  min-height: 40px;
  padding: 0 0 1px 4px;
  background-color: #fff;
}
.ImageComment div:first-child {
  width: 100%;
}
div[editext="view"] {
  padding: 10px;
}

button[editext="edit-button"] {
  display: none !important;
  /*border: none;*/
  /*background: none;*/
  /*right: 5px;*/
  /*position: absolute;*/
  /*bottom: 1px;*/
  /*height: auto;*/
}

button[editext="save-button"], button[editext="cancel-button"] {
  display: none !important;
}

div[editext] {
  min-height: 40px;
}

.Tools {
  position: relative;
}

.Infobox {
  //width: 30px;
  //height: 30px;
  //padding: 5px 10px 5px 13px;
  background-color: #CEE92B;
  //left: 2em;
  position: relative;
  //top: 0;
  margin-right: 8px;
  //height: 30%;
}

.Toolbox {
  display: block;
  //width: 90%;
  // height: 40%;
  background-color: #FFF62B;
  position: relative;
  top: 20px;
  margin-right: 10px;
}

.Timer {
  background-color: #FBC800;
  text-align: center;
  position: absolute;
  bottom: 0.1em;
  padding: 5px;
  left: 1em;
}
